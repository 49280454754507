import React, { Fragment, useEffect } from 'react';
import { RingLoader } from 'react-spinners';
import { ThemeProvider } from 'styled-components';
import { saasClassicTheme } from '../common/theme/index';
import ResetCSS from '../common/style';
import { GlobalStyle, ContentWrapper } from '../components/qstudy.style';
import BannerSection from '../components/Banner/index';
import FeatureSection from '../components/Feature';
import PricingSection from '../components/Pricing';
import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer';
import Disclaimer from '../components/Disclaimer';
import UserTestimonialSection from '../components/UserTestimonials';
import PartnerSection from '../components/Partner';
import StickyButton from '../components/StickyButton';
import FaqSection from '../components/FaqSection';
import useFetchClientConfig from '../api/FetchClientConfig';
import ImageCarousel from '../components/ImageCarousel/ImageCarousel';

const QStudyLandingPage = () => {

    const { response, error, isLoading, fetchClientConfig } = useFetchClientConfig();
    const clientName = 'qstudyworld';

    useEffect( () => {
        fetchClientConfig( clientName );
    }, [] )

    return (
        <>
            {
                ( isLoading ) ?
                    <div style={ { position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' } }>
                        <RingLoader color="#1a64d6" />
                    </div>
                    :
                    <ThemeProvider theme={ saasClassicTheme }>
                        <Fragment>
                            <ResetCSS />
                            <GlobalStyle />
                            <ContentWrapper>
                                <BannerSection clientConfig={ response?.data } />
                                <ImageCarousel />
                                <FeatureSection />
                                <PartnerSection clientConfig={ response?.data } />
                                <PricingSection clientConfig={ response?.data } />
                                <UserTestimonialSection />
                                <FaqSection />
                                <ContactUs />
                                <Disclaimer />
                                <Footer clientConfig={ response?.data } />
                                <StickyButton clientConfig={ response?.data } />
                            </ContentWrapper>
                        </Fragment>
                    </ThemeProvider>

            }

        </>

    );
};

export default QStudyLandingPage;
