import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import Button from '../../common/components/Button';
import { Image } from 'antd';
import Container from '../../common/components/UI/Container';

import PartnerSectionWrapper from './partner.style';
import Partner from '../../common/assets/SampleReportImage.png';

const PartnerSection = ( {
    row,
    col,
    title,
    description,
    button,
    textArea,
    imageArea,
    clientConfig
} ) => {

    const handleProceedToReport = () => {
        const baseUrl = 'https://careerresult.qstudyworld.com/?user_id=22032&report_id=13119';
        window.open( baseUrl );
    }

    return (
        <PartnerSectionWrapper>
            <Container width="1400px">
                <Box { ...row }>
                    <Box { ...col } { ...textArea }>
                        <Heading
                            { ...title }
                            content="See Your Career Path in Action: Download Your Personalized Report"
                        />
                        <Text
                            { ...description }
                            content="Get more than just a number! Our free career report analyzes your personality, interests, and aptitudes, providing actionable recommendations to guide your career journey."
                        />
                        <Box>
                            <button onClick={ handleProceedToReport } style={ { backgroundColor: 'transparent', border: 'none', cursor: 'pointer', textColor: clientConfig?.tertiary_color, fontSize: '18px', fontWeight: '500' } }>
                                <Text { ...button } style={ { textColor: clientConfig?.tertiary_color, textDecorationLine: 'underline' } } content="See Sample Report ➞" />
                            </button>
                        </Box>
                    </Box>
                    <Box { ...col } { ...imageArea }>
                        <Image src={ Partner } alt="Domain Image" preview={ false } />
                    </Box>
                </Box>
            </Container>
        </PartnerSectionWrapper>
    );
};

PartnerSection.propTypes = {
    row: PropTypes.object,
    col: PropTypes.object,
    title: PropTypes.object,
    description: PropTypes.object,
    button: PropTypes.object,
    textArea: PropTypes.object,
    imageArea: PropTypes.object,
    clientConfig: PropTypes.object,
};

PartnerSection.defaultProps = {
    row: {
        flexBox: true,
        flexWrap: 'wrap',
        ml: '-15px',
        mr: '-15px',
        alignItems: 'center',
    },
    imageAreaRow: {
        flexDirection: 'row-reverse',
    },
    col: {
        pr: '15px',
        pl: '15px',
    },
    textArea: {
        width: ['100%', '100%', '55%', '50%', '42%'],
    },
    imageArea: {
        width: ['100%', '100%', '45%', '50%', '58%'],
        mt: ['40px', '40px', '0', '0', '0'],
    },
    title: {
        fontSize: ['26px', '30px', '30px', '48px', '48px'],
        fontWeight: '700',
        color: '#000000',
        letterSpacing: '-0.025em',
        mb: '15px',
        lineHeight: '1.25',
    },
    description: {
        fontSize: '18px',
        color: '#504E4E',
        lineHeight: '1.75',
        mb: '33px',
    },
    button: {
        fontSize: '18px',
        fontWeight: '500',
    },
};

export default PartnerSection;