import React from 'react';
import Box from '../../common/components/Box';
import PropTypes from 'prop-types';
import Text from '../../common/components/Text';
import Container from '../../common/components/UI/Container';
import FooterArea from './footer.style';
import { Image } from 'antd';

const Footer = ( { clientConfig } ) => {
    return (
        <FooterArea>
            <Container>
                <Box className="logoBox">
                    <a className="logo">
                        <Image src={ clientConfig?.logo } alt="logo footer" width={ 150 } preview={ false } />
                    </a>
                </Box>
                <Box className="social">
                    <Text as="span" content="Copyright © 2024 All Rights Reserved to Q Study. Analytics Powered by Stride Ahead " />
                </Box>
            </Container>
        </FooterArea>
    );
};

Footer.propTypes = {
    clientConfig: PropTypes.object,
};

export default Footer;
