import React from 'react';
import { IMAGES } from '../../data';
import './ImageCarousel.css'; // We'll write the CSS here for infinite scroll

const ImageCarousel = () => {
    return (
        <div className="infinite-scroll-container">
            <div className="image-scroll">
                { IMAGES.map( ( image ) => (
                    <div key={ image.id } className="image-item">
                        <img src={ image.imageSrc } alt={ `Slide ${ image.id }` } />
                    </div>
                ) ) }
                {/* Repeat the images for seamless scrolling */ }
                { IMAGES.map( ( image ) => (
                    <div key={ `repeat-${ image.id }` } className="image-item">
                        <img src={ image.imageSrc } alt={ `Slide ${ image.id }` } />
                    </div>
                ) ) }
            </div>
        </div>
    );
}

export default ImageCarousel;
