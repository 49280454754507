import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import Container from '../../common/components/UI/Container';
import GlideCarousel from '../../common/components/GlideCarousel';
import GlideSlide from '../../common/components/GlideCarousel/glideSlide';

import { USERTESTIMONIALS } from '../../data/index';

import {
    TestimonialSlideWrapper,
    TestimonialItem,
    TestimonialMeta,
    AuthorInfo,
    AuthorImage,
} from './testimonial.style';
import { Image } from 'antd';

const TestimonialSection = ( {
    sectionWrapper,
    secTitleWrapper,
    secText,
    secHeading,
    reviewTitle,
    review,
    name,
    designation,
    secHeadingTwo,
} ) => {
    const carouselOptions = {
        type: 'carousel',
        autoplay: 6000,
        perView: 2,
        gap: 30,
        animationDuration: 800,
        breakpoints: {
            990: {
                perView: 1,
            },
        },
    };

    return (
        <Box { ...sectionWrapper } as="section" id="testimonial_section" style={ { backgroundColor: '#f9f9f9' } }>
            <Container>
                <Box { ...secTitleWrapper }>
                    <Heading
                        { ...secHeadingTwo }
                        content="Empowering Voices, Inspiring Choices: Our Success Stories!"
                    />
                </Box>
                <TestimonialSlideWrapper>
                    <GlideCarousel
                        options={ carouselOptions }
                        carouselSelector="testimonial__slider"
                        controls={ false }
                        bullets={ true }
                        numberOfBullets={ USERTESTIMONIALS.length }
                    >
                        <>
                            { USERTESTIMONIALS.map( ( item, index ) => (
                                <GlideSlide key={ `testimonial-slide-${ index }` }>
                                    <TestimonialItem>
                                        <Text content={ item.review } { ...review } />
                                        <TestimonialMeta>
                                            <AuthorInfo>
                                                <Box>
                                                    <Heading as="h3" content={ item.name } { ...name } />
                                                    <Heading as="h3" content={ item.designation } { ...designation } />
                                                </Box>
                                            </AuthorInfo>
                                        </TestimonialMeta>
                                    </TestimonialItem>
                                </GlideSlide>
                            ) ) }
                        </>
                    </GlideCarousel>
                </TestimonialSlideWrapper>
            </Container>
        </Box>
    );
};

TestimonialSection.propTypes = {
    sectionHeader: PropTypes.object,
};

TestimonialSection.defaultProps = {
    sectionWrapper: {
        pt: ['60px', '80px', '90px', '100px', '100px'],
        pb: ['60px', '80px', '90px', '100px', '100px'],
    },
    secTitleWrapper: {
        mb: ['40px', '60px'],
    },
    secText: {
        as: 'span',
        display: 'block',
        textAlign: 'center',
        fontSize: '14px',
        letterSpacing: '0.15em',
        fontWeight: '700',
        color: '#ff4362',
        mb: '12px',
    },
    secHeading: {
        textAlign: 'center',
        fontSize: ['15px', '15px', '15px', '17px'],
        fontWeight: '700',
        color: '#F1585A',
        letterSpacing: '0.15em',
        mb: '1rem',
        ml: 'auto',
        mr: 'auto',
        lineHeight: '1.12',
        width: '500px',
        maxWidth: '100%',
    },
    secHeadingTwo: {
        textAlign: 'center',
        fontSize: ['20px', '24px', '36px', '36px'],
        fontWeight: '700',
        color: '#000000',
        letterSpacing: '-0.025em',
        mb: '0',
        ml: 'auto',
        mr: 'auto',
        lineHeight: '1.12',
        width: '700px',
        maxWidth: '100%',
    },
    reviewTitle: {
        fontSize: ['15px', '16px'],
        fontWeight: '500',
        color: '#343d48',
        lineHeight: '1.5',
        mb: '13px',
    },
    review: {
        fontSize: ['16px', '19px'],
        fontWeight: '300',
        color: '#343d48',
        lineHeight: '1.7',
        mb: 0,
    },
    name: {
        fontSize: ['14px', '16px'],
        fontWeight: '500',
        color: '#0f2137',
        letterSpacing: '-0.025em',
        mb: '8px',
    },
    designation: {
        fontSize: ['12px', '14px'],
        color: '#6f7a87',
        mb: 0,
    },
};

export default TestimonialSection;
